import React, {useEffect, useState} from "react";
import "./About.css";
import {EmployeeCard} from "./EmployeeCard";
import noUser from "../../assets/img/no-user.png";
import groupPhoto from "../../assets/img/employees/group.jpg";
import {defaultLocale, locales} from "../../localization/locales";
import langs from "../../localization/languages/languages";
import employeeServices from "../../services/employeeServices";
import {backendServerUrl} from "../../globals";
import {semanticJoin, ownerFirst, translateTitle} from "../../util/util";

const titleOrder: Record<string, number> = {
	"OWNER": 1,
	"MANAGER": 2,
	"RECEPTIONIST": 3,
	"EDUCATOR": 4,
	"THERAPIST": 5
};
const altOrder = Object.keys(titleOrder).length + 1;

const getImageUrl = (e: Employee) => e.imageUrl ? (backendServerUrl + e.imageUrl) : noUser;

export const About = (props:any) => {
	let {lang = defaultLocale} = props.match?.params;
	let [employees, setEmployees] = useState<Employee[]>([]);

	if (locales.indexOf(lang) === -1) {
		lang = defaultLocale;
	}
	useEffect(() => {
		employeeServices.getAll()
			.then(setEmployees);
		window.scrollTo({top: 0});
		// eslint-disable-next-line
	}, []);

	const boss = employees.find(e => e.titles.indexOf("OWNER") !== -1);

	return (
		<div className="about-container">
			<h2 className="center gold mb-5">{langs[lang].about_group_title}</h2>
			<div className="container">
				<div className="row mb-5">
					<div className="col s12 l12 group-photo">
						<img src={groupPhoto} alt="Zen team"/>
					</div>
				</div>
				<div className="boss-card mt-5">
					<div className="boss-image">
						<img src={boss ? backendServerUrl + boss?.imageUrl : ""} alt="Owner" />
					</div>
					<h2 className="boss-name">{boss?.firstName} {boss?.lastName}</h2>
					<h5 className="boss-title">{boss?.titles?.sort(ownerFirst)
						.map(translateTitle(lang))
						.reduce(semanticJoin(lang))}</h5>
				</div>
				<div className="employee-container row">
					{(employees)
						.filter(e => e.titles.indexOf("OWNER") === -1)
						.sort((a, b) => {
							const minA = Math.min(...(a.titles.map(t => titleOrder[t] || altOrder)));
							const minB = Math.min(...(b.titles.map(t => titleOrder[t] || altOrder)));
							return minA - minB;
						})
						.map(e => <EmployeeCard
							name={`${e.firstName} ${e.lastName}`}
							titles={e.titles}
							key={e.id}
							img={getImageUrl(e)}/>)
					}
				</div>
			</div>
		</div>
	);
};
